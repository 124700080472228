import { useState, useEffect } from "react";

export const useProductsPerPage = () => {
  const [productsForPage, setProductsForPage] = useState(getProductsForPage());

  function getProductsForPage() {
    return window.innerWidth <= 768 ? 26 : 25;
  }

  useEffect(() => {
    const handleResize = () => setProductsForPage(getProductsForPage());
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return productsForPage;
};
