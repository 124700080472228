import { useEffect } from "react";
import axios from "axios";

export const useUpdateUser = () => {
  const updateUserData = async () => {
    try {
      const storedUser = localStorage.getItem("user");
      if (!storedUser) return;

      const user = JSON.parse(storedUser);
      const usersUrl = `https://zoho.accsolutions.tech/API/v1/Clientes_Report?where=Documento="${user.document_number}"`;

      const response = await axios.get(usersUrl);
      const fetchedUser = response.data.data?.[0];

      if (!fetchedUser) return;

      const updatedUser = {
        email: fetchedUser.Email,
        usuario: fetchedUser.Nombre,
        profile: fetchedUser.Tipo === "Aliado" ? "Mayorista" : fetchedUser.Tipo,
        document_number: fetchedUser.Documento,
        status: "loggedIn",
        availableAmount: fetchedUser.cupodDisponible,
      };

      const previousUser = JSON.stringify(user);
      const newUser = JSON.stringify(updatedUser);

      if (previousUser !== newUser) {
        localStorage.setItem("user", newUser);
        window.location.reload(); // Recargar la página para reflejar cambios
      }
    } catch (error) {
      console.error("Error actualizando datos del usuario:", error);
    }
  };

  useEffect(() => {
    updateUserData();
  }, []);
};
