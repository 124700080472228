function Register() {
  return (
    <div className="iframeZoho">
      <iframe
        className="iframeForm"
        frameBorder="0"
        scrolling="auto"
        src="https://creatorapp.zohopublic.com/vt.cel/tecnosuper/form-embed/Mayoristas_Usams/CxkyeVXq8mAfp2kBG8mVF3JGv3T5pns0WC4EmWqME0Zxv32mdK7eWqU4ytpzzz9yYYQKTtrmQ07EwSVKe7QtZAHhYd5AmAaS68XN"
      ></iframe>
    </div>
  );
}

export default Register;
