import { useState, useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

const URL_BASE_API = 'https://zoho.accsolutions.tech/API/v1/Productos_USAMS';

export const Products = ({
  groupProducts,
  setCurrentPage,
  setProducts,
  listProducts,
}) => {
  const [user] = useState(
    () => JSON.parse(localStorage.getItem('user')) ?? null,
  );
  const [brands, setBrands] = useState([]);
  console.log(user);

  useEffect(() => {
    fetch(`${URL_BASE_API}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data);
        const dataBrands = data.data.map((product) => product.Marca.Marca);

        const removeDuplicates = [...new Set(dataBrands)];

        console.log(removeDuplicates);
        // Verificar los datos obtenidos
        setBrands(Array.isArray(data) ? data : removeDuplicates);
      })
      .catch((error) => console.error('Error fetching brands:', error));
  }, []);

  const linkActive = (isActive) => {
    if (isActive) {
      return 'btn btn-category active';
    }

    return 'btn btn-category';
  };

  const resetCurrentPage = () => {
    setCurrentPage(1);
  };

  // const currency = (value) => new Intl.NumberFormat("es-CO", {
  //   style: "currency",
  //   currency: "COP",
  // }).format(value);

  const searchProducts = (e) => {
    setCurrentPage(1);
    const search_product = e.target.value.toLowerCase();
    if (search_product) {
      const filteredProducts = listProducts.filter(
        (product) =>
          product.Referencia.toLowerCase().includes(search_product) ||
          (product.Caracteristicas?.toLowerCase() || '').includes(
            search_product,
          ) ||
          product.Categoria.Categoria.toLowerCase().includes(search_product),
      );
      setProducts(filteredProducts);
    } else {
      setProducts(listProducts);
    }
  };

  /* const filterByBrand = (brand) => {
    setCurrentPage(1);
    const filteredProducts = listProducts.filter((product) => product.Marca.Marca === brand);
    setProducts(filteredProducts);
  }; */

  return (
    <>
      <section className="banner section w-full pt-10 flex items-center justify-center ">
        <div className="w-full">
          <div className="p-10 owl-carousel owl-theme w-full">
            <div className="item w-full">
              <div className="banner__img w-full">
                <img
                  src="./img/Banner-Usams.png"
                  alt="Banner 1"
                  className="w-full object-cover max-h-[400px]"
                />
              </div>
            </div>
            <div className="item w-full">
              <div className="banner__img w-full">
                <img
                  src="./img/Banners-Usams-2.png"
                  alt="Banner 2"
                  className="w-full object-cover "
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Productos */}
      <section className="products section">
        <div className="container fluid">
          <div className="row">
            <div className="col-100 col-100 col-mb-100">
              <div className="header-mb w-full flex flex-col items-center">
                <nav className="header__nav flex items-center justify-center w-full px-4 md:px-0">
                  <button className="btn btn-search p-2 rounded-full bg-gray-200">
                    {/* <img src="./img/search.png" alt="Buscar" className="w-6 h-6" /> */}
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                  <input
                    type="search"
                    placeholder="Buscar por marca o referencia"
                    id="search-product"
                    onChange={searchProducts}
                    className="bg-gray-100 text-black px-4 py-2 rounded-full w-full md:w-96 shadow-md outline-none"
                  />
                </nav>
              </div>
            </div>
          </div>
          {user && (
            <section className="user-info-card">
              {/* Sección Izquierda - Nombre de Usuario */}
              <div className="user-info">
                <span className="icon">
                  <i className="fa-solid fa-circle-user"></i>
                </span>
                <div className="user-details">
                  <span className="bold">
                    {user.usuario.charAt(0).toUpperCase() +
                      user.usuario.slice(1).toLowerCase()}
                  </span>
                  <span className="user-profile">{user.profile}</span>
                </div>
              </div>
              {/* Sección Derecha - Cupo Disponible */}
              <div className="user-info">
                <span className="icon">
                  <i className="fa-solid fa-wallet"></i>
                </span>
                <span className="cupo-text">Cupo disponible:</span>
                <span className="bold green">
                  {parseInt(user.availableAmount).toLocaleString('es-CO', {
                    style: 'currency',
                    currency: 'COP',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </span>
              </div>
            </section>
          )}
          <div className="row">
            <div className="col col-100 col-mb-100">
              <div className="products__category py-3">
                <h3 className="title">Categorías</h3>
                <div className="products__cont-categories">
                  <NavLink
                    to="/"
                    className={({ isActive }) => linkActive(isActive)}
                  >
                    {' '}
                    Todas{' '}
                  </NavLink>
                  {groupProducts &&
                    groupProducts.length !== 0 &&
                    groupProducts.map((group) => {
                      return (
                        <NavLink
                          key={group}
                          to={group}
                          className={({ isActive }) => linkActive(isActive)}
                          onClick={resetCurrentPage}
                        >
                          {' '}
                          {group}{' '}
                        </NavLink>
                      );
                    })}
                </div>
                <h3 className="title">Marcas</h3>
                <div className="products__cont-categories">
                  {brands &&
                    brands.length !== 0 &&
                    brands.map((brand) => {
                      return (
                        <NavLink
                          key={brand}
                          to={brand}
                          className={({ isActive }) => linkActive(isActive)}
                          onClick={() => {
                            resetCurrentPage();
                          }}
                        >
                          {' '}
                          {brand}{' '}
                        </NavLink>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col col-100 col-mb-100" style={{ margin: ' ' }}>
              <div className="row">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
