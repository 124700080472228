import { useEffect, useState } from 'react';
import { formatNumber } from '#helpers/formatNumbers.js';
import { RegisterSend } from './RegisterSend.js';
import { addDiscountPurchase } from '#helpers/addDiscountPurchase.js';

export const Cart = ({
  discountPurchase,
  setDiscountPurchase,
  setTotalDiscount,
  totalDiscount,
  productsCart,
  setProductsCart,
  iva,
  setIva,
  subtotal,
  setSubtotal,
  total,
  setTotal,
  isAuth,
  user,
  setUser,
}) => {
  const [alertSuccess, setAlertSuccess] = useState('');
  const [blockOptions, setBlockOptions] = useState(false);

  const closeCart = () => {
    const cart = document.querySelector('#cart');
    const trama = document.querySelector('.trama');

    cart.classList.remove('open-cart');
    trama.classList.remove('open-trama-styles');

    setTimeout(() => {
      trama.classList.remove('open-trama');
    }, 400);
  };

  const getListCart = () => {
    const products_cart = JSON.parse(localStorage.getItem('product_usams_asc'));
    setProductsCart(products_cart);
  };

  const deleteProductCart = (id) => {
    const new_products_cart = productsCart.filter(
      (product) => product.ID !== id,
    );
    let total = 0;
    let subtotal = 0;
    let iva = 0;

    setProductsCart(new_products_cart);
    localStorage.setItem(
      'product_usams_asc',
      JSON.stringify(new_products_cart),
    );

    new_products_cart.map((product) => {
      const iva_decimal = parseInt(product.GrupoDeProductos.IVA1) / 100;

      subtotal += product.precio - iva_decimal * product.precio;
      total += product.precio;
      iva += iva_decimal * product.precio;
    });

    setTotal(total);
    setSubtotal(subtotal);
    setIva(iva);

    addDiscountPurchase(total, discountPurchase, setTotal, setTotalDiscount);
  };


  const increaseQuantity = (e, id) => {
    let subtotal = 0;
    let total = 0;
    let iva = 0;

    const new_products_cart = productsCart.map((product) => {
      const priceProduct =
        isAuth && user.profile === 'Mayorista'
          ? parseInt(product.Precio_Mayorista)
          : isAuth && user.profile === 'Distribuidor'
            ? parseInt(product.Precio_Distribuidor)
            : parseInt(product.Precio_detal);

      if (product.ID === id) {
        product.quantity++;
        product.precio = Math.round(priceProduct * product.quantity);
      }

      const iva_decimal = parseInt(product.GrupoDeProductos.IVA1) / 100;

      subtotal += Math.round(product.precio - iva_decimal * product.precio);
      total += product.precio;
      iva += Math.round(iva_decimal * product.precio);

      return product;
    });

    setSubtotal(subtotal);
    setTotal(total);
    setIva(iva);
    setProductsCart(new_products_cart);
    localStorage.setItem(
      'product_usams_asc',
      JSON.stringify(new_products_cart),
    );

    addDiscountPurchase(total, discountPurchase, setTotal, setTotalDiscount);
  };

  const reduceQuantity = (e, id) => {
    let subtotal = 0;
    let total = 0;
    let iva = 0;

    const new_products_cart = productsCart.map((product) => {
      const priceProduct =
        isAuth && user.profile === 'Mayorista'
          ? parseInt(product.Precio_Mayorista)
          : isAuth && user.profile === 'Distribuidor'
            ? parseInt(product.Precio_Distribuidor)
            : parseInt(product.Precio_detal);

      if (product.ID === id && product.quantity > 1) {
        product.quantity--;
        product.precio = priceProduct * product.quantity;
      }

      const iva_decimal = parseInt(product.GrupoDeProductos.IVA1) / 100;

      subtotal += product.precio - iva_decimal * product.precio;
      total += product.precio;
      iva += iva_decimal * product.precio;

      return product;
    });

    setSubtotal(subtotal);
    setTotal(total);
    setIva(iva);
    setProductsCart(new_products_cart);
    localStorage.setItem(
      'product_usams_asc',
      JSON.stringify(new_products_cart),
    );

    addDiscountPurchase(total, discountPurchase, setTotal, setTotalDiscount);
  };

  const modifyQuantity = (e, id) => {
    let subtotal = 0;
    let total = 0;
    let iva = 0;

    e.target.value = e.target.value.replace(/\D/g, '');
    e.target.value = e.target.value !== '0' ? e.target.value : 1;

    const input_value = e.target.value;

    const new_products_cart = productsCart.map((product) => {
      const priceProduct =
        isAuth && user.profile === 'Mayorista'
          ? parseInt(product.Precio_Mayorista)
          : isAuth && user.profile === 'Distribuidor'
            ? parseInt(product.Precio_Distribuidor)
            : parseInt(product.Precio_detal);

      if (product.ID === id) {
        product.quantity = input_value;
        product.precio = priceProduct * product.quantity;
      }

      const iva_decimal = parseInt(product.GrupoDeProductos.IVA1) / 100;

      subtotal += product.precio - iva_decimal * product.precio;
      total += product.precio;
      iva += iva_decimal * product.precio;

      return product;
    });

    setSubtotal(subtotal);
    setTotal(total);
    setIva(iva);
    setProductsCart(new_products_cart);
    localStorage.setItem(
      'product_usams_asc',
      JSON.stringify(new_products_cart),
    );

    addDiscountPurchase(total, discountPurchase, setTotal, setTotalDiscount);
  };

  const closeAlert = () => {
    const alert = document.querySelector('.alert');
    const progress = document.querySelector('.alert-progress');

    alert.classList.remove('active');
    progress.classList.remove('active');
  };

  useEffect(() => {
    getListCart();

    let total = 0;
    let subtotal = 0;
    let iva = 0;

    const products_cart = localStorage.getItem('product_usams_asc')
      ? JSON.parse(localStorage.getItem('product_usams_asc'))
      : [];

    products_cart.map((product) => {
      const iva_decimal = parseInt(product.GrupoDeProductos.IVA1) / 100;

      subtotal += product.precio - iva_decimal * product.precio;
      total += product.precio;
      iva += iva_decimal * product.precio;
    });

    setSubtotal(subtotal);
    setTotal(total);
    setIva(iva);

    addDiscountPurchase(total, discountPurchase, setTotal, setTotalDiscount);
  }, []);

  return (
    <>
      <div className="cart" id="cart">
        <div className="cart__header-img">
          <img src="./img/logo-usams-blanco.png" alt="" />
        </div>

        <div className="cart__close-cart" id="close-cart" onClick={closeCart}>
          <i className="fa-solid fa-xmark"></i>
        </div>

        <div className="container cart__container">
          <div className="row">
            <div className="col col-100">
              <h3 className="title">Carrito de Compras</h3>
            </div>
          </div>

          <div className="row row-center">
            <div className="col col-66 col-mb-100">
              <table className="table cart__table">
                <thead>
                  <tr>
                    <th>Producto</th>
                    <th>Cantidad</th>
                    <th>Precio Total</th>
                    <th className="cart__delete-all-text">Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  {productsCart && productsCart.length !== 0 ? (
                    productsCart.map((product) => {
                      return (
                        <tr key={product.id} id={product.ID}>
                          <td className="cart__data">
                            <div className="cart__img">
                              <img src={product.Imagen_publica.url} alt="" />
                            </div>
                            <div className="cart__information">
                              <span className="cart__name-product">
                                {product.Referencia}
                              </span>
                              <span className="cart__category">
                                {product.Tipo.Nombre}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="cart__quantity">
                              <div
                                className="cart__operation cart__minus"
                                id="minus"
                                onClick={
                                  !blockOptions
                                    ? (e) => reduceQuantity(e, product.ID)
                                    : null
                                }
                              >
                                <i className="fa-solid fa-minus"></i>
                              </div>

                              <input
                                type="text"
                                className="cart__input-quantity"
                                id="quantity"
                                onChange={
                                  !blockOptions
                                    ? (e) => modifyQuantity(e, product.ID)
                                    : null
                                }
                                value={product.quantity}
                                disabled={blockOptions ? true : false}
                              />

                              <div
                                className="cart__operation cart__plus"
                                id="plus"
                                onClick={
                                  !blockOptions
                                    ? (e) => increaseQuantity(e, product.ID)
                                    : null
                                }
                              >
                                <i className="fa-solid fa-plus"></i>
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            {formatNumber(product.precio, true)}
                          </td>
                          <td className="text-center ">
                            <div
                              className="cart__delete"
                              id={product.ID}
                              onClick={
                                !blockOptions
                                  ? () => deleteProductCart(product.ID)
                                  : null
                              }
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        El carrito esta vacío
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="col col-33 col-mb-100 position-relative">
              <RegisterSend
                setTotalDiscount={setTotalDiscount}
                totalDiscount={totalDiscount}
                discountPurchase={discountPurchase}
                setDiscountPurchase={setDiscountPurchase}
                iva={iva}
                setTotal={setTotal}
                total={total}
                subtotal={subtotal}
                productsCart={productsCart}
                setProductsCart={setProductsCart}
                setAlertSuccess={setAlertSuccess}
                setBlockOptions={setBlockOptions}
                user={user}
                setUser={setUser}
                isAuth={isAuth}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="alert">
        <div className="alert-content">
          {alertSuccess === true ? (
            <i className="fa-solid fa-circle-check blue"></i>
          ) : (
            ''
          )}
          {alertSuccess === false ? (
            <i className="fa-solid fa-triangle-exclamation orange"></i>
          ) : (
            ''
          )}
          <div className="alert-description">
            {alertSuccess === true ? (
              <>
                <span className="text-bold">Enviado</span>
                <span>El usuario ya existe en nuestra base de datos</span>
              </>
            ) : (
              ''
            )}

            {alertSuccess === false ? (
              <>
                <span className="text-bold">Error</span>
                <span>El cupo disponible es insuficiente para realizar el pedido</span>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="alert-close" onClick={closeAlert}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="alert-progress"></div>
      </div>

      <div className="trama"></div>
    </>
  );
};