export const addDiscountPurchase = async (
  total,
  discountPurchase,
  setTotal,
  setTotalDiscount,
) => {
  if (discountPurchase !== null) {
    const discount_percentage = parseInt(discountPurchase.Porcentaje) / 100;
    const new_total = total - total * discount_percentage;
    const discount_total = total * discount_percentage;

    setTotal(new_total);
    setTotalDiscount(discount_total);
  }
};
