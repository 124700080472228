import '#App.css';
import { MainRouter } from '#routes/MainRouter.js';
import { useUpdateUser } from '#hooks/useUpdateUser.js';

function App() {
  useUpdateUser();

  return (
    <div className="layout">
      <MainRouter />
    </div>
  );
}

export default App;
