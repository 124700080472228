import { MdEmail, MdLock } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

function Login({ setIsModalOpen, validateUser, isAuth }) {
  const { handleSubmit, register } = useForm();
  const [email, setEmail] = useState('');

  return (
    <div className="wrapper">
      <form
        action=""
        onSubmit={handleSubmit(async (values) => {
          if (isAuth) return;
          const isValid = await validateUser(values);
          isValid !== false && setTimeout(() => window.location.reload(), 10);
        })}
      >
        <div className="headerFormLogin">
          <h1>Login </h1>
          <span
            className="closeFormLogin"
            onClick={() => setIsModalOpen(false)}
          >
            x
          </span>
        </div>
        <div className="input-box">
          <input
            type="text"
            {...register('email', { required: true })}
            placeholder="Correo"
            value={email}
            onInput={(e) => setEmail(e.target.value.toLowerCase())}
          />
          <MdEmail className="icon" />
        </div>
        <div className="input-box">
          <input
            type="password"
            {...register('password', { required: true })}
            placeholder="Contraseña"
          />
          <MdLock className="icon" />
        </div>
        <button type="submit" className="login__btn">
          Iniciar Sesion
        </button>
        <div className="additional-buttons">
          <button
            type="button"
            onClick={() =>
              (window.location.href = 'https://usams.com.co/register')
            }
            className="secondary-btn"
          >
            Registrate / Olvide mi contraseña
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
