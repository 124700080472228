
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Products } from '#components/Products.js';
import { Header } from '#components/layout/Header.js';
import { Footer } from '#components/layout/Footer.js';
import Register from '#components/Register.js';
import { Cart } from '#components/Cart.js';
import { ProductsCategory } from '#components/ProductsCategory.js';
import { DetailProducts } from '#components/DetailProducts.js';
import Swal2 from 'sweetalert2';

export const MainRouter = () => {
  //const URL_BASE_GROUP = "https://nexyapp-f3a65a020e2a.herokuapp.com/zoho/v1/console/GrupoDeProductos_Report?where=ID%3D1889220000051935384";
  const URL_BASE = 'https://zoho.accsolutions.tech/API/v1/Productos_USAMS';
  const [productsCart, setProductsCart] = useState(null);
  const [iva, setIva] = useState('');
  const [subtotal, setSubtotal] = useState('');
  const [total, setTotal] = useState('');
  const [totalDiscount, setTotalDiscount] = useState(null);
  const [discountPurchase, setDiscountPurchase] = useState(null);

  const [groupProducts, setGroupProducts] = useState([]);
  const [brandProducts, setBrandProducts] = useState([]);
  const [products, setProducts] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [productDetail, setProductDetail] = useState('');
  const [isAuth, setIsAuth] = useState(() => {
    const savedAuth = localStorage.getItem('isAuth');
    return savedAuth === 'true' ? savedAuth : false;
  });
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [listProducts, setListProducts] = useState([]);

  //Cargar los productos de 1 hora desde la API
  useEffect(() => {
    const getGroupProductsAPI = async () => {
      const load = document.querySelector('.load-display');

      const group_products_api = await axios.get(URL_BASE, {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });

      let categories = [];
      group_products_api.data.data.forEach((group) => {
        categories.push(group.Tipo.Nombre);
      });

      categories = [...new Set(categories)];
      setGroupProducts(categories);

      await load.classList.add('opacity');
      await setTimeout(() => {
        load.classList.add('hide');
      }, 300);

      const { data: dataBrands } = group_products_api.data; 
      const formattedBrands = dataBrands.map((product) => product.Marca.Marca);
      const brands = [...new Set(formattedBrands)]; 

      setBrandProducts(brands);

    };

    getGroupProductsAPI();

    const getProductsAPI = async () => {
      try {
        const products_api = await axios.get(URL_BASE, {
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
          },
        });

        const order_products = products_api.data.data.sort((a, b) => {
          if (a.Promosion === 'Si' && b.Promosion !== 'Si') return -1;
          if (a.Promosion !== 'Si' && b.Promosion === 'Si') return 1;
          return 0;
        });

        
        setProducts(await order_products);
      } catch (error) {
        console.error('Error al traer los productos - error: ' + error);
      }
    };

    getProductsAPI();
  }, []);

  useEffect(() => {
    localStorage.setItem('isAuth', isAuth);
    localStorage.setItem('user', JSON.stringify(user));
  }, [user, isAuth]);

  let allProducts = []; 

  const validateUser = async (data) => {
    try {
      const { email, password } = data;
      const URL_MAYORISTAS = `https://zoho.accsolutions.tech/API/v1/Mayoristas_Usams_Report?where=Email="${email}"`;
      const result = await axios.get(URL_MAYORISTAS);
      const fetchedUser = result.data.data[0];
      if (fetchedUser.password !== password && fetchedUser.email !== email) {
        Swal2.fire({
          title: 'Usuario o contraseña incorrecta',
          text: 'Revisa los datos ingresados en los campos',
          icon: 'error',
          confirmButtonColor: '#4FC0AD',
          confirmButtonText: 'Cerrar',
        });
        return false;
      }

      const userName = `https://zoho.accsolutions.tech/API/v1/Clientes_Report?where=Documento="${fetchedUser.document_number}"`;
      const resultUser = await axios.get(userName);
      const fetcUser = resultUser.data.data[0];

      if (!fetcUser) {
        Swal2.fire({
          title: 'Usuario no encontrado',
          text: 'El usuario no se encuentra en la base de datos de clientes. Por favor, contactar a la administración',
          icon: 'error',
          confirmButtonColor: '#4FC0AD',
          confirmButtonText: 'Cerrar',
        });
        return false;
      }

      const loggedUser = {
        email: fetchedUser.Email,
        usuario: fetcUser.Nombre,
        profile: fetcUser.Tipo === 'Aliado' ? 'Mayorista' : fetcUser.Tipo,
        document_number: fetchedUser.document_number,
        status: 'loggedIn',
        availableAmount: fetcUser.cupodDisponible // Agregas este campo
      };

      localStorage.setItem('product_usams_asc', '[]');
      setUser(loggedUser);
      setIsAuth(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BrowserRouter>
      <Header
        total={total}
        setUser={setUser}
        validateUser={validateUser}
        isAuth={isAuth}
        setIsAuth={setIsAuth}
        products={products}
        setProducts={setProducts}
        setCurrentPage={setCurrentPage}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        listProducts={listProducts}
        setListProducts={setListProducts}
      />

      <main>
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route
            path="/"
            element={
              <Products
                groupProducts={groupProducts}
                setCurrentPage={setCurrentPage}
                setProducts={setProducts}
                listProducts={listProducts}
                setListProducts={setListProducts}
              />
            }
          >
            <Route
              path="/"
              element={
                <ProductsCategory
                  user={user}
                  isAuth={isAuth}
                  discountPurchase={discountPurchase}
                  setTotalDiscount={setTotalDiscount}
                  productsCart={productsCart}
                  setProductsCart={setProductsCart}
                  setIva={setIva}
                  setSubtotal={setSubtotal}
                  setTotal={setTotal}
                  search="true"
                  products={products}
                  setProducts={setProducts}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setProductDetail={setProductDetail}
                />
              }
            />

            
          
            {groupProducts &&
              groupProducts.length !== 0 &&
              groupProducts.map((group) => {
                const new_products = [];

                products.forEach((product) => {
                  if (product.Tipo.Nombre === group) {
                    new_products.push(product);
                  }
                });
                console.log(new_products);
                return (
                  <>
                    <Route
                      path={group}
                      element={
                        <ProductsCategory
                          user={user}
                          isAuth={isAuth}
                          discountPurchase={discountPurchase}
                          setTotalDiscount={setTotalDiscount}
                          category={group}
                          productsCart={productsCart}
                          setProductsCart={setProductsCart}
                          setIva={setIva}
                          setSubtotal={setSubtotal}
                          setTotal={setTotal}
                          products={new_products}
                          setProducts={setProducts}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          setProductDetail={setProductDetail}
                        />
                      }
                    />
                  </>
                );
              })}
            
            
          
              {brandProducts &&
                brandProducts.length !== 0 &&
                brandProducts.map((brand) => {
                  const new_products = [];

                  products.forEach((product) => {
                    if (product.Marca.Marca === brand) {
                      new_products.push(product);
                    }
                  });
                    
                  return (
                    <>
                      <Route
                        path={brand}
                        element={
                          <ProductsCategory
                            user={user}
                            isAuth={isAuth}
                            discountPurchase={discountPurchase}
                            setTotalDiscount={setTotalDiscount}
                            productsCart={productsCart}
                            setProductsCart={setProductsCart}
                            setIva={setIva}
                            setSubtotal={setSubtotal}
                            setTotal={setTotal}
                            products={new_products}
                            setProducts={setProducts}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            setProductDetail={setProductDetail}
                          />
                        }
                      />
                    </>
                  );
                })}
            
            
              
            </Route>
          
          
        </Routes>
      </main>

      <Footer />
      <Cart
        discountPurchase={discountPurchase}
        setDiscountPurchase={setDiscountPurchase}
        totalDiscount={totalDiscount}
        setTotalDiscount={setTotalDiscount}
        productsCart={productsCart}
        setProductsCart={setProductsCart}
        iva={iva}
        setIva={setIva}
        subtotal={subtotal}
        setSubtotal={setSubtotal}
        total={total}
        setTotal={setTotal}
        isAuth={isAuth}
        user={user}
        setUser={setUser}
      />
      <DetailProducts
        user={user}
        isAuth={isAuth}
        discountPurchase={discountPurchase}
        setTotalDiscount={setTotalDiscount}
        productsCart={productsCart}
        productDetail={productDetail}
        setProductsCart={setProductsCart}
        setIva={setIva}
        setSubtotal={setSubtotal}
        setTotal={setTotal}
      />
    </BrowserRouter>
  );
};
