export const Footer = () => {
  return (
    <>
      <footer className="footer">
        {/* <div className="container">
                <div className="row">
                    <div className="col col-100">
                        <div className="footer__content-img">
                            <img src="./img/Fondo 1Hora.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div> */}

        {/*  <div className="footer__logos">
                <div className="footer__img-logo">
                    <img src="./img/Logo-asinfy.svg" alt="" />
                </div>

                <div className="footer__img-logo">
                    <img src="./img/logo-usams-blanco.png" alt=""/>
                </div>
            </div> */}

        <div className="footer__info">
          <div className="container">
            <div className="row">
              <div className="col col-33 col-mb-50 col-100-sm-mb">
                <div className="center-block">
                  <img src="./img/logo-usams.png" />
                </div>
                <div className="footer__social-network">
                  <div className="footer__cont-social-network">
                    {/* <img src="./img/social-network/whatsapp.svg" /> */}
                    <a
                      href="https://api.whatsapp.com/send/?phone=%2B573011778476&text&type=phone_number&app_absent=0"
                      target="_blank"
                    >
                      <i className="fa-brands fa-whatsapp"></i>
                    </a>
                  </div>
                  <div className="footer__cont-social-network">
                    {/* <img src="./img/social-network/instagram.svg" /> */}
                    <a
                      href="https://www.instagram.com/usamscol?utm_source=qr"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <div className="footer__cont-social-network">
                    {/* <img src="./img/social-network/youtube.svg" /> */}
                    <a
                      href="https://www.youtube.com/@USAMS_GLOBAL"
                      target="_blank"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                  </div>
                  <div className="footer__cont-social-network">
                    {/* <img src="./img/social-network/tiktok.svg" /> */}
                    <a href="https://www.tiktok.com/tag/usams" target="_blank">
                      <i className="fa-brands fa-tiktok"></i>
                    </a>
                  </div>
                
                </div>
              </div>
              <div className="col col-33 col-mb-50 col-100-sm-mb">
                <div className="footer__contact">
                  <h3>CONTÁCTANOS</h3>

                  <ul>
                    <li>
                      <span>Atención al cliente:</span> Lunes a Viernes 8am -
                      6pm
                    </li>
                    <li>
                      <span>Correo:</span> soporte@usams.com.co
                    </li>
                    <li>
                      <span>Dirección:</span> Km 3.6 Vía La Ceja - Rionegro,
                      Mall Cantarrana Local 22
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col col-33 col-mb-50 col-100-sm-mb ">
                <div>
                  <div className="footer__rights">
                    <h3>LEGAL</h3>
                    <a href="https://www.tecnosuper.co/terms" target="_blank">
                      Política de Tratamiento de datos
                    </a>
                    <div className="footer__brands">
                      <img src="./img/Logo Industria y Comercio.svg" />
                      <a target="blank" href="https://asincode.co">
                        <img src="./img/logo-asincode.png" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer__text">
          <span>
            Copyright &copy; {new Date().getFullYear()} Tecnosuper - USAMS.
            Desarrollado por{' '}
            <b>
              <a target="blank" href="https://asincode.co">
                Asincode.
              </a>
            </b>
          </span>
        </div>
      </footer>
      <div className="load load-display">
        <div className="center-block logo-display">
          <img src="./img/logo-usams.png"></img>
        </div>
        <div className="loader"></div>
      </div>
    </>
  );
};
