import { formatNumber } from '#helpers/formatNumbers.js';
import { addProductCart } from '#helpers/addProductsCart.js';
import { useProductsPerPage } from '#hooks/useProductsPerPage.js';
import { useState, useEffect } from 'react';

//category = '', currentPage, setCurrentPage

export const ProductsCategory = ({
  discountPurchase,
  setTotalDiscount,
  productsCart,
  setProductsCart,
  setSubtotal,
  setTotal,
  products,
  setIva,
  setProductDetail,
  isAuth,
  user,
  currentPage,
  setCurrentPage
}) => {
  const URL_BASE_API = 'https://zoho.accsolutions.tech/API/v1/Productos_USAMS';

  const total_products = products.length;
  const productsForPage = useProductsPerPage();
  const pageNumbers = [];
  const lastIndex = currentPage * productsForPage;
  const firstIndex = lastIndex - productsForPage;

  //Paginación
  for (let i = 1; i <= Math.ceil(total_products / productsForPage); i++) {
       pageNumbers.push(i);

    }

  const onPreviusPage = () => {
        setCurrentPage(currentPage - 1);
    }

    const onNextPage = () => {
        setCurrentPage(currentPage + 1);
    }

    const OnSpecificPage = (n) => {
        setCurrentPage(n);
    }

  //Agregar productos al carrito
  const addProduct = async (e, id) => {
    addProductCart(
      e,
      id,
      URL_BASE_API,
      setProductsCart,
      setTotal,
      setSubtotal,
      setIva,
      discountPurchase,
      setTotalDiscount,
      isAuth,
      user,
    );
  };

  const openProductDetail = (product) => {
    const cont_detail = document.querySelector('#detail-product');
    const trama = document.querySelector('.trama');

    trama.classList.add('open-trama');
    cont_detail.classList.add('open');
    setTimeout(() => {
      trama.classList.add('open-trama-styles');
      cont_detail.classList.add('show');
    }, 300);

    setProductDetail(product);
  };

  const closeAlert = () => {
    const alert = document.querySelector('.alert');
    const progress = document.querySelector('.alert-progress');

    alert.classList.remove('active');
    progress.classList.remove('active');
  };

  const getPercentage = (price_before, price) => {
    const desc = parseInt((price * 100) / price_before);

    return 100 - desc;
  };

  // Paginación
  // const page
  const categoria = window.location.pathname.split('/')[1];

  const [cardCargada, setcardCargada] = useState({});

  const handleCargaImagen = (productId) => {
    setTimeout(() => {
      setcardCargada((prev) => ({ ...prev, [productId]: true }));
    }, 1000);
  };

  const [primerRender, setprimerRender] = useState(true);

  useEffect(() => {
    setcardCargada({});
    setprimerRender(false);
  }, []);

  useEffect(() => {
    if (!primerRender) {

      setcardCargada({});
      return;
    }
  }, [currentPage,categoria]);

  return (
    <>
      {
        products &&
          products.length !== 0 &&
          products
            .filter((product) =>
              isAuth && user.profile === 'Mayorista'
                ? product.Precio_Mayorista > '0'
                : isAuth && user.profile === 'Distribuidor'
                  ? product.Precio_Distribuidor > '0'
                  : product.Precio_detal > '0',
            )
            .map((product) => {
              const iscardCargada = cardCargada[product.id] || false;

              return (
                <div className="col col-20 col-mb-50" key={product.id}>
                  <article className="products__card-product">
                    <div className="products__cont-img">
                      <div
                        className="products__card-img"
                        onClick={() => openProductDetail(product)}
                      >
                        {!iscardCargada && (
                          <div className="loaderContainer">
                            <div className="loader"></div>
                          </div>
                        )}
                        <img
                          src={`${product.Imagen_publica.url}`}
                          alt=""
                          onLoad={() => handleCargaImagen(product.id)}
                          style={{
                            display: iscardCargada ? 'block' : 'none',
                          }}
                        />
                      </div>

                      <div
                        className="products__options"
                        style={{
                          display: iscardCargada ? '' : 'none',
                        }}
                      >
                        {/* <button className="btn btn-blue">Comprar</button> */}

                        <div
                          className="products__options-product option-add-product"
                          id={product.ID}
                          onClick={
                            productsCart !== null &&
                            productsCart.find((item) => item.ID === product.ID)
                              ? null
                              : (e) => addProduct(e, product.ID)
                          }
                        >
                          {productsCart !== null &&
                          productsCart.find(
                            (item) => item.ID === product.ID,
                          ) ? (
                            <i className="fa-solid fa-check"></i>
                          ) : (
                            <>
                              <div className="load-add-cart display-none">
                                <div className="loader"></div>
                              </div>
                              <img src="./img/cart-usams.png" alt="" />
                            </>
                          )}
                        </div>
                        <div
                          className="products__options-product"
                          onClick={() => openProductDetail(product)}
                        >
                          <img src="./img/icon-details.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="products__card-description translate=no">
                      <span
                        className="products__type"
                        style={{
                          display: iscardCargada ? 'block' : 'none',
                        }}
                      >
                        {product.Marca.Marca}
                      </span>
                      <h3
                        className="products__title"
                        style={{
                          display: iscardCargada ? 'block' : 'none',
                        }}
                      >
                        {product.Referencia}
                      </h3>

                      <div className="products__cont-price-cart">
                        <div
                          className="products__cont-price"
                          style={{
                            display: iscardCargada ? 'block' : 'none',
                          }}
                        >
                          {/* Cambiar para mas adelante el precio del producto */}
                          {product.Promosion !== null &&
                          product.Promosion === 'Si' &&
                          product.PrecioComparacion !== 0 &&
                          product.PrecioComparacion !== null ? (
                            <>
                              <span className="products__price">
                                {formatNumber(product.PrecioComparacion, true)}{' '}
                                COP
                              </span>
                              <span className="products__price-before">
                                {formatNumber(
                                  isAuth && user.profile === 'Mayorista'
                                    ? product.Precio_Mayorista
                                    : isAuth && user.profile === 'Distribuidor'
                                      ? product.Precio_Distribuidor
                                      : product.Precio_detal,

                                  true,
                                )}{' '}
                                COP
                              </span>
                            </>
                          ) : (
                            <span className="products__price">
                              {formatNumber(
                                isAuth && user.profile === 'Mayorista'
                                  ? product.Precio_Mayorista
                                  : isAuth && user.profile === 'Distribuidor'
                                    ? product.Precio_Distribuidor
                                    : product.Precio_detal,

                                true,
                              )}{' '}
                              COP
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    {product.Promosion !== null &&
                    product.Promosion === 'Si' &&
                    product.PrecioComparacion !== 0 &&
                    product.PrecioComparacion !== null ? (
                      <div className="products__discounts">
                        <span>
                          -
                          {getPercentage(
                            isAuth && user.profile === 'Mayorista'
                              ? product.Precio_Mayorista
                              : isAuth && user.profile === 'Distribuidor'
                                ? product.Precio_Distribuidor
                                : 0,
                            product.PrecioComparacion,
                          )}
                          % OFF
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                  </article>
                </div>
              );
            }).slice(firstIndex, lastIndex)
      }

      {/* Paginación */}
      <div className='col col-100'>
            <nav aria-label="pagination-products">
                <ul className="pagination">
                    <li className="page-item">
                        {currentPage !== 1 ? (
                            <a className="page-link" onClick={onPreviusPage} aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </a>

                        ) : ''}
                    </li>
                        {pageNumbers.map(noPage => {
                            return(
                                <li className="page-item" key={noPage}>
                                    <a className={`page-link ${noPage === currentPage ? 'active' : ''}`} onClick={() => OnSpecificPage(noPage)}>{noPage}</a>
                                </li>
                            )
                        })}


                    <li className="page-item">
                        { currentPage < pageNumbers.length ? (
                            <a className={`page-link`}  onClick={onNextPage} aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        ): ''}
                    </li>
                </ul>
            </nav>
        </div>

      <div className="alert">
        <div className="alert-content">
          <i className="fa-solid fa-circle-check blue"></i>

          <div className="alert-description">
            <span className="text-bold">Agregado al carrito</span>
          </div>
          <div className="alert-close" onClick={closeAlert}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="alert-progress"></div>
      </div>
    </>
  );
};
