import { useEffect } from 'react';
import { formatNumber } from '#helpers/formatNumbers.js';
import Login from '#components/Login.js';

export const Header = ({
  total,
  setUser,
  setProducts,
  setCurrentPage,
  setIsModalOpen,
  isModalOpen,
  isAuth,
  validateUser,
  setIsAuth,
  listProducts,
  setListProducts,
}) => {
  useEffect(() => {
    const getProductsAPI = async () => {
      try {
        const URL_BASE =
          'https://zoho.accsolutions.tech/API/v1/Productos_USAMS';
        const products_api = await fetch(URL_BASE);
        const { data } = await products_api.json();

        const order_products = data.sort((a, b) => {
          if (a.Promosion === 'Si' && b.Promosion !== 'Si') return -1;
          if (a.Promosion !== 'Si' && b.Promosion === 'Si') return 1;
          return 0;
        });

        setProducts(order_products);
        setListProducts(order_products);
      } catch (error) {
        console.error(`Error al traer los productos - Error: ${error.message}`);
      }
    };

    getProductsAPI();
  }, []);

  const localStorageUser =
    localStorage.getItem('user') && localStorage.getItem('user') !== null
      ? JSON.parse(localStorage.getItem('user'))
      : '';

  const openCart = () => {
    document.querySelector('#cart')?.classList.add('open-cart');
    document.querySelector('.trama')?.classList.add('open-trama');
    setTimeout(() => {
      document.querySelector('.trama')?.classList.add('open-trama-styles');
    }, 100);
  };

  const searchProducts = (e) => {
    setCurrentPage(1);
    const search_product = e.target.value.toLowerCase();
    if (search_product) {
      const filteredProducts = listProducts.filter(
        (product) =>
          product.Referencia.toLowerCase().includes(search_product) ||
          (product.Caracteristicas?.toLowerCase() || '').includes(
            search_product,
          ) ||
          product.Categoria.Categoria.toLowerCase().includes(search_product),
      );
      setProducts(filteredProducts);
    } else {
      setProducts(listProducts);
    }
  };

  return (
    <>
      <header className="header-ppal">
        <div className="header">
          <div className="header__container container">
            <div className="header__logo">
              <a href="/">
                <img
                  className="header__imgLog"
                  src="./img/logo-usams-blanco.png"
                  alt="Logo Usams"
                />
              </a>
            </div>
            <nav className="header__nav">
              <button className="btn btn-search">
                {/* <img src="./img/search.png" alt="Buscar" /> */}
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
              <input
                type="search "
                placeholder="Filtrar por producto"
                id="search-product"
                onChange={searchProducts}
              />
            </nav>
            <div className="header__cart" onClick={openCart}>
              <div className="btn btn-cart" id="btn-cart">
                <img src="./img/cart-white.png" alt="Carrito" />
              </div>
              <div className="header__text-cart">
                <span>Tu carrito</span>
                <span className="header__price-cart">
                  {formatNumber(total, true)} COP
                </span>
              </div>
            </div>
            <div>
              <span
                className="login"
                onClick={() => {
                  if (!isAuth) {
                    setIsModalOpen(true);
                  } else {
                    localStorage.setItem('product_usams_asc', '[]');
                    setUser(null);
                    setIsAuth(false);
                    window.location.reload();
                  }
                }}
              >
                {!isAuth ? (
                  'Iniciar sesión'
                ) : (
                  <>
                    {localStorageUser?.usuario || ''}
                    <img
                      src="./img/boton-eliminar.png"
                      alt="Eliminar"
                      style={{
                        marginLeft: '5px',
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
      </header>
      {isModalOpen && (
        <Login
          validateUser={validateUser}
          isAuth={isAuth}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};
