import '#App.css';
import { MainRouter } from '#routes/MainRouter.js';

function App() {
  return (
    <div className="layout">
      <MainRouter />
    </div>
  );
}

export default App;
